import { render, staticRenderFns } from "./MegaMenuSheetMobile.vue?vue&type=template&id=2468ff36&scoped=true&"
import script from "./MegaMenuSheetMobile.vue?vue&type=script&lang=js&"
export * from "./MegaMenuSheetMobile.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuSheetMobile.vue?vue&type=style&index=0&id=2468ff36&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2468ff36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseImage: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default})
